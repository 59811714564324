import React from 'react';
import LoginModal from './LoginModal';
import RegisterModal from './RegisterModal';
import SubscribeModal from './SubscribeModal';
import ForgotModal from './ForgotModal';

const HeaderModals = ({
                        showLoginModal,
                        showRegisterModal,
                        showSubscribeModal,
                        showForgotModal,
                        handleCloseModal,
                        handleLoginModal,
                        handleRegisterModal,
                        handleSubscribeModal,
                        i18n
                      }) => (
  <>
    <LoginModal
      show={showLoginModal}
      handleCloseModal={handleCloseModal}
      handleRegisterModal={handleRegisterModal}
      i18n={i18n}
    />
    <RegisterModal
      show={showRegisterModal}
      handleCloseModal={handleCloseModal}
      handleLoginModal={handleLoginModal}
      i18n={i18n}
    />
    <SubscribeModal
      show={showSubscribeModal}
      handleCloseModal={handleCloseModal}
      handleSubscribeModal={handleSubscribeModal}
      i18n={i18n}
    />
    <ForgotModal
      show={showForgotModal}
      handleCloseModal={handleCloseModal}
      handleLoginModal={handleLoginModal}
      i18n={i18n}
    />
  </>
);

export default HeaderModals;
