import React from 'react';
import { Modal, ModalClose, ModalContent, ModalFooter, ModalHeader } from '../../../modal/Modal';
import { Title2 } from '../../../../components/Text/styles';
import { Button } from 'finform';
import RoboadvisorSubscribe from '#app/pages/Auth/RoboadvisorSubscribe';

const SubscribeModal = ({ show, handleCloseModal, handleSubscribeModal, i18n }) => (
  <Modal
    size='extra-small'
    onClose={handleCloseModal}
    show={show}
    trigger={''}
  >
    <ModalHeader>
      <Title2 center style={{ width: '100%', lineHeight: '28px' }}>
        Уведомить о запуске <br /> Robo-advisor
      </Title2>
    </ModalHeader>
    <ModalContent>
      <RoboadvisorSubscribe callback={handleSubscribeModal} />
    </ModalContent>
    <ModalFooter>
      <Button flex style={{ margin: '5px' }} className='style1' onClick={handleCloseModal}>
        {i18n.auth.cancel}
      </Button>
    </ModalFooter>
  </Modal>
);

export default SubscribeModal;
