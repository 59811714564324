import React, {createContext, useContext, useEffect, useState} from 'react';
import {IndexedDBCache} from "finhelper";
import {connect} from "react-redux";
import BalanceHelper from "#app/helpers/BalanceHelper";
import CacheHelper from "#app/helpers/CacheHelper";

const InvestsContext = createContext();

const fetchData = async (client, currency, accounts, courses, setData) => {
  let cacheKey = CacheHelper.generateKey(CacheHelper.BALANCE, {
    clientId: client.id,
    currencyId: currency.id
  });

  if(!BalanceHelper.loading)
  {
    BalanceHelper.loading = true;

    let data = await IndexedDBCache.get(cacheKey);

    if(!data){
      let newState = await BalanceHelper.loadData({}, accounts, client, currency, courses);

      await IndexedDBCache.set(cacheKey, newState);//тут обязательно нужно подождать иначе объекты будут обработаны в модели ActiveModel и в кэш уже не сохранить

      BalanceHelper.convertDataToActives(newState);
      setData(newState);
    }else{
      BalanceHelper.convertDataToActives(data);

      setData(data);
    }

    BalanceHelper.loading = false;
  }else{
    let data = await IndexedDBCache.get(cacheKey);

    if(!data)
    {
      let intervalId = setInterval(async () => {
        let data = await IndexedDBCache.get(cacheKey);

        if(data){
          BalanceHelper.convertDataToActives(data);

          clearInterval(intervalId);
          setData(data);
        }
      }, 1000);
    }else{
      BalanceHelper.convertDataToActives(data);
      setData(data);
    }
  }
};


const InvestsProvider = ({ client, currency, courses, accounts, balanceHash, children }) => {
  const [data, setData] = useState({
    wholeActiveSum: 0,
    wholeObligationSum: 0,
    bankAccountValuation: 0,
    brokerAccountValuation: 0,
    cashAccountValuation: 0,
    digitAccountValuation: 0,
    spendingSum: 0,
    propertyActiveSum: 0,
    propertyOtherSum: 0,
    investActiveSum: 0,
    investOtherSum: 0,
    obligationCurrentSum: 0,
    obligationLongSum: 0,
    obligationLongOtherSum: 0,
    obligationCurrentOtherSum: 0,

    accounts: [],
    actives: [],
    invests: [],
    properties: [],
    spendings: [],
    obligations: [],
    cashBalance: {},
    bankBalance: {},
    brokerBalance: {},
    digitBalance: {},

    propertyPixels: 0,
    investPixels: 0,
    currentPixels: 0,
    obligationLongTermPixels: 0,
    obligationCurrentPixels: 0,

    currentSum: 0,
    currentPercent: 0,
    investSum: 0,
    investPercent: 0,

    accountsPrepared: [],
  });

  useEffect(() => {
    if(client && currency && accounts.length && courses.length)
    {
      fetchData(client, currency, accounts, courses, setData);
    }
  }, [client?.id, currency?.id, accounts, courses, balanceHash]);

  return (
    <InvestsContext.Provider value={{ data, setData }}>
      {children}
    </InvestsContext.Provider>
  );
};

const enhance = connect(
  state => ({
    client: state.interfaceComponents.client,
    currency: state.interfaceComponents.currency,
    accounts: state.interfaceComponents.accounts,
    currencies: state.interfaceComponents.currencies,
    formData: state.scenario.data,
    courses: state.interfaceComponents.courses,
    i18n: state.i18n,
    tutorial: state.scenario.tutorial,
    demo: state.scenario.demo,
    balanceHash: state.interfaceComponents.balanceHash,
  }),
  {  }
);

export default enhance(InvestsProvider);

export const useInvests = () => useContext(InvestsContext);