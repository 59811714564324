import React from 'react';
import { Modal, ModalClose, ModalContent, ModalFooter, ModalHeader } from '../../../modal/Modal';
import { Title2 } from '../../../../components/Text/styles';
import { Button } from 'finform';
import Forgot from '../../../../pages/Auth/Forgot';

const ForgotModal = ({ show, handleCloseModal, handleLoginModal, i18n }) => (
  <Modal
    size='extra-small'
    onClose={handleCloseModal}
    show={show}
    trigger={''}
  >
    <ModalHeader>
      <Title2>{i18n.auth.forgotPassword}</Title2>
      <ModalClose onClick={handleCloseModal}><div /></ModalClose>
    </ModalHeader>
    <ModalContent>
      <Forgot callback={handleLoginModal} />
    </ModalContent>
    <ModalFooter>
      <Button flex className='style1' onClick={handleCloseModal}>
        {i18n.auth.cancel}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ForgotModal;
