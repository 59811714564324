
export default class CacheHelper
{
  static BALANCE = 'balance_{clientId}.{currencyId}';
  static PORTFOLIO = 'portfolio_data_{clientId}.{currencyId}';
  static TACTICS_MONTH = 'tactics_by_month_{clientId}';
  static CURRENCIES = 'currencies';
  static COURSES = 'courses_{date}';

  static generateKey = (pattern, params) => {
    Object.keys(params).forEach(key => {
      // Replace all occurrences of {param} in the pattern with corresponding values
      pattern = pattern.replace(new RegExp(`{${key}}`, 'g'), params[key]);
    });

    return pattern;
  };

}