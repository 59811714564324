import React from 'react'

export default {
  common: {
    shortMode: 'Краткий режим',
    extendedMode: 'Расширенный режим',
    deleteButton: 'Удалить',
    cancelButton: 'Отмена',
    saveButton: 'Сохранить',
    saveAndContinueButton: 'Сохранить и продолжить',
    saveAndCloseButton: 'Сохранить и закрыть',
    editButton: 'Редактировать',
    calcButton: 'Рассчитать',//цель
    showBy: 'Показывать по',
    noData: 'Нет данных',
    forbidden: 'У вас недостаточно прав, авторизуйтесь',
    notFound1: 'Ничего не найдено',
    notFound2: 'Попробуйте вернуться назад или поищите что-нибудь другое.',
    notFound3: 'Код ошибки: 404',
    notFound4: 'На главную',
    editPhoto: 'Редактирование фото',
    back: 'Вернуться назад',
    loadPhoto: 'Загрузите фото',
    dropPhoto: 'Загрузите фото',
    mb: 'мб',
    sizeMax: 'Размер изображения не более',
    sizeMin: 'Размер изображения не менее 0 мб',
    format: 'Формат изображения jpg, jpeg, gif или png',
    quantity: 'Кол-во',
  },
  accounts: {
    title: 'Счета / Активы / Обязательства',
    accounts: 'Счета',
    archiveAccounts: 'Архив счетов',
    cash: 'Наличные',
    bank: 'Банковские',
    broker: 'Брокерские',
    user: 'Пользовательские',
    addAccount: 'Добавить счёт',
    editAccount: 'Редактирование счёта',
    newAccount: 'Новый счёт',
    addActive: 'Добавить актив',
    editActive: 'Редактирование актива',
    newActive: 'Новый актив',
    addOblig: 'Добавить обязательство',
    editOblig: 'Редактирование обязательства',
    newOblig: 'Новое обязательство',
    actives: 'Активы',
    activesPrivate: 'Личные',
    activesInvest: 'Инвестиционные (не финансовые)',
    archiveActives: 'Архив активов',
    obligs: 'Обязательства',
    typeObligs: 'Тип обязательства',
    archiveObligs: 'Архив обязательств',
    currenctObligs: 'Текущие',
    longObligs: 'Долгосрочные',
    subAccounts: 'Субсчета',
    nameSubAccount: 'Название субсчёта',
    balance: 'Баланс',
    currency: 'Валюта',
    nameDeposit: 'Название депозита',
    descDeposit: 'Описание депозита',
    accountDeposit: 'Счет с которого внесён депозит',
    sumDeposit: 'Сумма депозита',
    startDeposit: 'Дата и время начала депозита',
    endDeposit: 'Дата и время завершения депозита',
    procentType: 'Тип процента',
    procentYear: 'Процент (годовых)',
    periodEarn: 'Период начисления',
    accountDepositEarn: 'Счет зачисления процентов',
    nameAccount: 'Название счёта',
    typeAccount: 'Тип счёта',
    bankName: 'Название банка',
    brokerName: 'Название брокера',
    addSubAccount: 'Добавить субсчет',
    temp: 'Временные',
    currencySum: 'Валюта суммы',
    deletingAccount: 'Удаление счёта',
    deleteAccount: 'Скрыть счёт',
    subAccount: 'Субсчёт',
    confirmDeleteAccount: 'Вы уверены что хотите скрыть счёт?',
    confirmDeleteSubAccount: 'Вы уверены что хотите удалить субсчёт?',
    typeActive: 'Тип актива',
    buyType: 'Способ приобретения актива',
    typeObject: 'Тип объекта',
    groupInvest: 'Группа инвестиции',
    name: 'Название',
    desc: 'Описание',
    buyActive: 'Покупка актива',
    getActive: 'Получение актива',
    buyAccount: 'Счёт покупки',
    debtAccount: 'Счёт списания',
    sumIn: 'Сумма довнесения',
    dateIn: 'Дата и время довнесения',
    sellActive: 'Продажа актива',
    sellAccount: 'Счёт продажи',
    sellPrice: 'Цена продажи',
    sellDate: 'Дата и время продажи',
    buyPrice: 'Цена покупки',
    buyDate: 'Дата и время покупки',
    valuePrice: 'Оценочная цена',
    getDate: 'Дата и время получения',
    addDeposit: 'Добавить депозит',
    newInvest: 'Новая инвестиция',
    editInvest: 'Редактирование инвестиции',
    typeInvest: 'Тип инвестиции',
    tag: 'Тэг',
    classification: 'Классификация',
    addInvesting: 'Добавить инвестирование',
    activeName: 'Название актива',
    activeDesc: 'Описание',
    paymentSum: 'Сумма платежа',
    period: 'Периодичность',
    start: 'Дата и время начала',
    end: 'Дата и время завершения',
    newOperation: 'Новая операция',
    editOperation: 'Редактирование операции',
    confirm: 'Вы уверены что хотите удалить перевод?',
    transfer: 'Перевод между счетами',
    all: 'Итого',
    addSubaccount: 'Добавить субсчёт',
    current: 'Текущие',
    invest: 'Инвестиционные',
    other: 'Другое',
    brokerAccount: 'Брокерские счета',
    long: 'Долгосрочные',
    private: 'Личные',
    operations: 'Операции',
    operationAccount: 'Счёт операции',
    logsDate: 'Дата и время',
    logsAccount: 'Счёт',
    logsReason: 'Назначение платежа',
    logsSum: 'Сумма',
    logsCommission: 'Комиссия',
    logsBalance: 'Баланс',
  },
  dashboard: {
    incomesOutcomes: 'Бюджет',
    payments: 'Обязательства',
    paymentsPlan: <>Обязательные платежи<br/> для соблюдения плана</>,
    priceChange: 'Изменение стоимости активов',
    portfolio: 'Портфель',
    currentPriceActive: 'Текущая стоимость активов',
    avgPriceActive: 'Средняя стоимость активов',
    period: 'Период',
    inc: 'Дох.',
    outc: 'Расх.',
  },
  sidebar: {
    wealthy: 'Благосостояние',
    current: 'Текущие',
    invest: 'Инвестиционные',
    broker: 'Брокерс...',
    other: 'Другое',
    private: 'Личные',
    long: 'Долгосрочные',
    oblig: 'Обязательства',
    brokerAccount: 'Брокерские счета',
    cash: 'Наличные',
    cards: 'На картах',
    user: 'Пользовательские счета',
    actives: 'Активы'
  },
  menu: {
    dashboard: 'Дашборд',
    dynamic: 'Динамика состояния',
    accounts: <>Счета /<br/> Активы /<br/> Обязательства</>,
    operations: 'Операции',
    clients: 'Клиенты',
    fillProfile: 'Заполните профиль',
    incomes: 'Доходы',
    outcomes: 'Расходы',
    plan: 'Планирование',
    goals: 'Цели',
    charts: 'Графики',
    investList: 'Список инвестиций',
    diagrams: 'Диаграммы',
    view: 'Обзор',
    incomesOutcomes: 'Бюджет',
    planing: 'Планирование',
    invest: 'Инвестиции',
    config: 'Настройки',
    support: 'Поддержка',
    profile: 'Профиль',
    logout: 'Выход',
  },
  mainPage: {
    title: 'Сервис для финансового учёта Whiteswan',
    mainTitle: 'Сервис для персонального финансового планирования',
    mainTitle2: 'Платформа по контролю расходов<br/>и планированию сбережений',
    functions: 'Какие функции уже доступны в&nbsp;whiteswan',
    functions1: 'Ручной ввод доходов и расходов',
    functions2: 'Учет активов и обязательств',
    functions3: 'Планирование расходов и доходов',
    functions4: 'Расчёт финансовых целей',
    functions5: 'Учет финансовых и нефинансовых инвестиций',
    functions6: 'Визуализация инвестиционного портфеля финансовых целей',
    block1: 'Система скоринга финансового состояния',
    block11: 'Анализируй активы и обязательства',
    block12: 'Структурируй активы по группам: текущие, инвестиционные, личного пользования. Проведи анализ в разрезе обязательств по каждой группе.',
    block13: 'Ежедневный динамичный расчет финансовых коэффициентов поможет оценить текущее финансовое состояние.',
    block2: 'Синхронизация с банком',
    block21: 'Контролируй свои расходы',
    block22: 'Посмотри на расходы с другой стороны – каждая необязательная трата отодвигает исполнение целей, найди свой баланс. Анализируй изменения внутри различных групп расходов в динамике. Результат позволит прогнозировать чистый денежный поток (разница текущих доходов и текущих расходов)',
    block3: 'Умные накопления',
    block31: 'Запиши цели и составь финансовый план',
    block32: 'Создан положительный денежный поток (текущие доходы больше текущих расходов). Посмотри достаточно ли его для достижения финансовых целей. Все считается автоматически, но можно использовать ваши планы, занесенные вручную. Следи за динамикой выполнения финансового плана – вноси корректировки.',
    block4: 'Портфель инвестиций',
    block41: 'Следи за динамикой инвестиционного портфеля',
    block42: 'Под каждую цель создается отдельный инвестиционный портфель. Подбирайте финансовые продукты в разрезе личного принятия риска и срока достижения цели, а мы покажем динамику по каждой группе.',
    haveAdviser: 'Есть финансовый консультант?',
    shareLink: 'Отправь ссылку на CRM блок для финансовых консультантов и работай с советником на единой платформе.',
    visit: 'Перейти на сайт',
    proposal: 'Ждем ваши предложения',
    company: 'ООО «ДАТА ТЕКНОЛОДЖИС ЭНД АНАЛИТИКС КОНСАЛТИНГ»',
    inn: 'ИНН: 7604323536, ОГРН: 1177627011836',
    agreement: 'Пользовательское соглашение',
    politic: 'Политика обработка данных',
  },
  auth: {
    register: 'Регистрация',
    enter: 'Вход',
    login: 'Войти',
    haveProfile: 'У вас есть аккаунт?',
    crm: 'CRM для финансовых консультантов',
    cancel: 'Отмена',
    forgotPassword: 'Забыли пароль',
    toRegister: 'Зарегистрироваться',
    promoCode: 'Промокод',
    password: 'Пароль',
    passwordRepeat: 'Повтор пароля',
    issue: <>(напишите на <a href="mailto:info@whiteswan.finance">info@whiteswan.finance</a> если код не приходит)</>,
    code: 'Код',
    codeAdvise: 'Введите код отправленный на',
    confirm: 'Подтвердить',
    fa: 'Код 2FA',
    createNewPassword: 'Придумайте новый пароль',
    newPassword: 'Новый пароль',
    newPasswordRepeat: 'Повтор нового пароля',
    save: 'Сохранить',
    restore: 'Восстановить',
  },
  config: {
    outcomes: 'Настройки категорий расходов',
    outcomes2: 'Выберете категории для отображения на графиках. Также вы можете добавить свои категории.',
    outcomes3: 'Системные',
    outcomes4: 'Пользовательские',
    createCategory: 'Создать категорию',
    editCategory: 'Редактирование категории',
    incomes: 'Настройки категорий доходов',
    incomes2: 'Выберете категории для отображения на графиках. Также вы можете добавить свои категории.',
    incomes3: 'Системные',
    incomes4: 'Пользовательские',
    name: 'Название',
    tags: 'Тэги',
    budgetTemplate: 'Шаблоны бюджетов',
    addCategory: 'Добавить категорию',
    add: 'Добавить',
    contactApp: 'Взаимодействие с приложением',
    zenmoney: 'Дзенмани',
    configBind: 'Вы можете настроить связь для получения расходов и доходов, чтобы не добавлять эти данные вручную',
    addUser: 'Добавить пользователя',
    bind: 'Связать',
    unbind: 'Отвязать',
    tinkoff: 'Тинькофф Инвестиции',
    configBindTinkoff: 'Вы можете настроить связь для получения операций брокерского счета, чтобы не добавлять эти данные вручную.',
    token: 'Токен',
    beginAgain: 'Начать все сначала',
    beginAgainText: 'Если вы хотите пройти все шаги настройки приложения заново, нажимайте “Начать”. Все внесенные данные будут удалены.',
    deleteAccount: 'Удалить счета',
    delete: 'Удалить',
    deleteAccountText: 'Удаляя счет, также будут удалены все связи с ним.',
    security: 'Настройка безопасности',
    securityGoogle: 'Вы можете настроить авторизацию с Google Authenticator',
    currencySaved: 'Валюта сохранена',
    languageSaved: 'Язык изменен',
    config: 'Настройка системы',
    mainCurrency: 'Основная валюта',
    enable2Fa: 'Включить 2FA',
    disable2Fa: 'Отключить 2FA',
    privateConfig: 'Настройка приватности',
    currentPassword: 'Текущий пароль',
    adminShow: 'Админу видны цифры в расходах',
    adminOperation: 'Фин. советнику запрещено осуществлять операции с интегриррованного счёта Тинькофф инвестиции',
    language: 'Язык',
  },
  incomeOutcome: {
    outcome: 'Расходы',
    income: 'Доходы',
    addOutcome: 'Добавить расход',
    addIncome: 'Добавить доход',
    date: 'Дата',
    sum: 'Сумма',
    category: 'Категория',
    subcategory: 'Подкатегория',
    tag: 'Тэг',
    desc: 'Описание',
    buyAccount: 'Счет списания',
    sellAccount: 'Счет зачисления',
    unsetFilter: 'Сбросить фильтр',
    filter: 'Фильтр',
    addCategory: 'Добавить категорию',
    month: 'Месяц',
    period: 'Период',
    configCategory: 'Настроить категории',
    incomeOutcomePerPeriod: 'Доходы и расходы за период',
    monthes12: 'За 12 месяцев',
    byYear: 'По годам',
    configBudget: 'Настроить бюджеты',
    configBudget2: 'Настройка бюджета',
    overspend: 'У вас перерасход',
    leftBudget: 'В бюджете осталось',
    configTemplate: 'Настроить шаблоны',
    noBudgets: 'Нет созданных бюджетов',
    create: 'Создать',
    select: 'Выбрать',
    countMoney: 'Количество денег на начало периода',
    incomes: 'Доходы',
    changeActives: 'Изменения активов личного пользования',
    invest: 'Инвестиционный поток',
    unconfirmedOperation: 'Неподтвержденные операции',
    selectTemplate: 'Выбор шаблона',
    templateWithoutName: 'Шаблон без названия',
    from: 'от',
    to: 'до',
    incomeTo: 'Поступления',
  },
  plan: {
    chartLife: 'График жизненного цикла',
    positiveScenario: 'Положительный сценарий',
    negativeScenario: 'Негативный сценарий',
    neutralScenario: 'Нейтральный сценарий',
    period: 'Период',
    positive: 'Положит.',
    neutral: 'Нейтральн.',
    negative: 'Негативный',
    middle: 'Среднее',
    tax: 'Налог',
    addIncome: 'Доп. доход',
    clear: 'Чистыми',
    outcome: 'Расходы',
    addOutcome: 'Доп. расход',
    obligations: 'Обязательства',
    goals: 'Цели',
    diff: 'Остаток',
    probability: 'Вероятность',
    index: 'Индексация',
    inCarier: 'Во время карьеры',
    inRetire: 'На пенсии',
    ageRetire: 'Возраст выхода на пенсию',
    ageOnRetire: 'Сколько лет на пенсии',
    monthCarierStart: 'Месяц начала карьеры',
    extended: 'Подробно',
    short: 'Кратко',
    carier: 'Карьера',
    retire: 'Пенсия',
    monthPayment: 'Месяца выплат',
    allFor: 'Итого за ',
    year: 'г.',
    hands: 'На руки',
    contract: 'По договору',
    toAllPeriods: 'Распространить на следущие периоды',
    reachGoal: 'Достижение цели',
    yes: 'Да',
    no: 'Нет',
    nameGoal: 'Название цели',
    countGoal: 'Количество выплат',
    startGoal: 'Дата начала накопления',
    endGoal: 'К какой дате',
    percentGoal: 'Размер ставки (годовых)',
    alreadySaved: 'Сколько уже отложено',
    inflationGoal: 'Размер инфляции (годовых)',
    closePayment: 'Ближайший платеж',
    retireAgePayment: 'Сколько лет выплачивать на пенсии',
    currentSpendPerYear: 'Текущий расход в год',
    periodPaymentGoal: 'Выберите период пополнения',
    createGoal: 'Создать цель',
    newGoal: 'Создание цели',
    editGoal: 'Редактирование цели',
    sortGoal: 'Сортировка целей',
    finPlan: 'Финансовое планирование',
    priority: 'Приоритет целей',
    typeGoal: 'Тип цели',
    countPayment: 'Кол-во платежей',
    calcPayment: 'Расчёт ежемесячного платежа',
    futureValue: 'Будущая стоимость',
    tabChart: 'График',
    tabByGroups: 'По группам',
    scenario: 'Сценарий',
    shortGoal: 'Краткосрочная цель',
    middleGoal: 'Среднесрочная цель',
    longGoal: 'Долгосрочная цель',
    withBind: 'С привязкой',
    withoutBind: 'Без привязки',
    rentier: 'Рантье',
    rentierWithInflation: 'Рантье с инфляцией',
    retireFund: 'Пенсионный фонд',
    control: 'Учитывать',
    notControl: 'Не учитывать',
    goalsPriority: 'Приоритет целей',
    sumPayment: 'Сумма',
    datePayment: 'Дата',
    payment: 'выплаты',
    monthlyNotEqual: 'Ежемесячное превышение доходов над расходами',
    planPayments: 'Спланировано платежей',
    freeLeft: 'Свободный остаток',
    lessThan100: 'Сумма должна быть меньше 100',
    spreadToNextMonthes: 'Распространить на следующие месяцы',
  },
  invest: {
    stats: 'Статистика',
    currentPriceActives: 'Текущая стоимость активов',
    avgPriceActives: 'Средняя стоимость активов',
    profitPerPeriod: 'Доходность за период',
    expectedProfit: 'Ожидаемая доходность',
    profitYear: 'Доходность годовая',
    maxLose: 'Максимальная просадка',
    koefSharp: 'Коэффициент Шарпа',
    koefSortino: 'Коэффициент Сортино',
    byGroup: 'По группе',
    byType: 'По типу',
    byAccount: 'По счёту',
    value: 'Стоимость портфеля',
    term: 'Срок',
    period: 'Период',
    portfolio: 'Портфель',
    div: 'Дивиденды',
    invest: 'Список инвестиций',
    archiveInvest: 'Архив сделок',
    addInvest: 'Добавить финансовый актив',
    buySellActive: 'Покупка/продажа финансового актива',
  },
  profile: {
    profile: 'Профиль',
    photo: 'Фото',
    enterName: 'Введите имя',
    enterEmail: 'Введите email',
    enterSurname: 'Введите фамилию',
    enterPhone: 'Введите телефон',
    enterMiddlename: 'Введите отчество',
    enterBirthDate: 'Дата рождения',
    enterStartDate: 'Дата внесения информации об активах',
    tutorial: 'Пройдите определенные шаги настройки приложения',
    start: 'Начать',
    begin: 'Начало',
    tutorial1: 'Модуль бюджет',
    tutorial2: 'Модуль список<br/> инвестиций',
    tutorial3: 'Модуль планирования',
    tutorial4: 'Заполнение счетов, активов',
    tutorial5: 'Пройти обучение',
    finish: 'Завершить обучение',
    next: 'Далее',
    lesson1: {
      page1: 'Страница профиль, необходимо заполнить обязательные поля и нажать кнопку "Сохранить"',
      page2: 'Окно "Благостояниеие" показывает сводную информацию по вашим финансам',
      page3: 'Для перехода на главный дашборд, нажмите.',
      page4: 'На этом дашборде будет ваша текущая финансовая ситуация',
      page5: 'Выберите в меню раздел "Счета / Активы / Обязательства"',
      page6: 'Блок "Счета" содержит информацию о всех остатках',
      page7: 'Для создания счёта, необходимо нажать кнопку "Создать счёт"',
      page8: 'Форма создания нового счёта',
      page9: 'Назовите новый счет, например "Сбер_зарплата".\nНе рекомендуем  писать просто название банка , так как в нем может быть несколько счетов, или счета вашего струга/партнера с кем вы совместно ведете финансовый учет.',
      page10: 'Выберите тип счета. Каждый тип обладает своей логикой. Если не знаете наверняка - выбирайте пользовательский тип счета.',
      page11: 'Придумайте название субсчета, например "Зарплата".',
      page12: 'Выведите текущий баланс по счету.',
      page13: 'Выберите валюту.',
      page14: 'Внесите несколько счетов в одном банке, например, есть счета в разных валютах.',
      page15: 'Обратите внимание депозиты для банковских счетов офорляются отдельно от текущих счетов.',
      page16: 'После окончания заполнения показываем счета.',
      page17: 'Заполните форму и сохраниет счета',
      page18: 'Теперь вы видите свои счета и остатки денег на них',
      page19: 'Обучение завершено',
    },
    fillForm: 'Заполните персональные данные',
  }
}
