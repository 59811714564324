import {call, put, select, takeLatest} from 'redux-saga/effects'
import * as planActions from '../actions/plan'
import {Api} from "laravel-request";
import {IndexedDBCache, Tracker} from "finhelper"
import moment from "moment/moment";
import CacheHelper from "#app/helpers/CacheHelper";

function* getObligations(action)
{
  try {
    const {client} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.get('active', 'index', {
        currency_id: client.currency_id,
        user_id: client.id
      })
        .whereObligationType(true)
        .with('buy_currency')
        .with('sell_currency')
        .with('income_currency')
        .with('buy_account')
        .with('sell_account')
        .with('income_account')
        .with('payments')
        .all((response) =>
        {
          resolve(response);
        }, (errors) => {
          console.error(errors);
        })
    });

    let response = yield promise;

    yield put(planActions.handleObligations({response, client}));

    yield put(planActions.getFactIncome({
      client: client
    }));

  } catch (err) {

    console.error(err);
  }
}

function* getPlan(action)
{
  try {
    const state = yield select();

    if(state.interfaceComponents.client)
    {
      let clientId = state.interfaceComponents.client.id;

      const promise = new Promise((resolve, reject) => {
        Api.get('user-plan', 'index', {user_id: clientId})
          .orderBy('buy_at')
          .all((response) =>
          {
            resolve(response);
          }, (errors) => {
            console.error(errors);
          })
      });

      let response = yield promise;
      yield put(planActions.handlePlan({
        response: response,
        client: state.interfaceComponents.client
      }));

      yield put(planActions.getObligations({
        client: state.interfaceComponents.client,
      }));
    }
  } catch (err) {

    console.error(err);
  }
}

function* savePlan(action)
{
  try {
    const state = yield select();

    const {data, obj, callback, error} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.putArg('profile', 'plan', state.interfaceComponents.client.id, data)
        .withValidateForm(obj)
        .call((response) => {
          Tracker.reachGoal('planing_created', {
            //user_id: this.props.client ? this.props.client.id : null
          });
          resolve(response)
      }, (errors) => {
          console.error(errors);
          error(errors);

          throw new Error('Error save plan');
        })
    });

    let response = yield promise;

    if(typeof callback === "function")
    {
      yield call(callback);
    }

  } catch (err) {

    console.error(err);
  }
}

function* getFactIncome(action)
{
  try {
    const {client} = action.payload;

    let rememberTime = 1000 * 60 * 60 * 24;//1000 дней

    const promise = new Promise(async (resolve, reject) => {
      //TODO сделать проверки что план обновлён, тогда загружать снова
      let cacheKey = CacheHelper.generateKey(CacheHelper.TACTICS_MONTH, {
        clientId: client.id
      });

      let data = await IndexedDBCache.get(cacheKey)

      if(!data || true)//пока кэш не обновляется, всегда true
      {
        Api.get('cabinet', 'tactics-by-month', {
          client_id: client.id,
          currency_id: client.currency_id,
          start_date: moment(client.start_enter_at_date, 'DD.MM.YYYY').startOf('year').format('DD.MM.YYYY'),
          end_date: moment(client.start_enter_at_date, 'DD.MM.YYYY').startOf('year').add(100, 'years').format('DD.MM.YYYY'),
        }).call(async (response) => {
          await IndexedDBCache.set(cacheKey, response, rememberTime)

          resolve(response);
        });
      }else{
        resolve(data);
      }
    });

    let response = yield promise;
    yield put(planActions.handleFactIncome({response, client}));

    yield put(planActions.getGoals({
      client: client
    }));

  } catch (err) {

    console.error(err);
  }
}

function* getGoals(action)
{
  try {
    const {client} = action.payload;

    const promise = new Promise((resolve, reject) => {
      Api.get('active-goal', 'index', {
          user_id: client.id,
          currency_id: client.currency_id,
        })
        .with('goal_payments', (query) => {
          return query.with('currency')
            .orderBy('paid_at');
        })
        .with('currency')
        .all((response) => {
          resolve(response);
        })
    });

    let response = yield promise;
    yield put(planActions.handleGoals({response, client}));
  } catch (err) {

    console.error(err);
  }
}

export default function* watcher() {
  yield takeLatest(planActions.getPlan, getPlan)
  yield takeLatest(planActions.savePlan, savePlan)
  yield takeLatest(planActions.getObligations, getObligations)
  yield takeLatest(planActions.getFactIncome, getFactIncome)
  yield takeLatest(planActions.getGoals, getGoals)
}
